'use client'
import React, { useEffect, useState } from 'react';
import {
    onAuthStateChanged,
    getAuth,
} from 'firebase/auth';
import {
    getFirestore,
    getDoc,
    doc,
    DocumentReference,
} from 'firebase/firestore'
import firebase_app from '@/app/firebase/config';
import LoadingDialog from '../components/LoadingDialog';


const auth = getAuth(firebase_app);

export const AuthContext = React.createContext({ 
    user: { 
        uid: null, 
        credit: { 
            free: { updated_time: 0, token_count: 0 },
            paid: { updated_time: 0, token_count: 0 }
        },
        saved: [],
        user_type: { updated_time: 0, type: 'normal' },
        idToken: '',
        displayName: '',
        user_type: {
            type: 'normal'
        },
        email: ''
    },
    setUpdateUser: (param) => {},
    loading: true
})

export const useAuthContext = () => React.useContext(AuthContext);

const getCurrentUser = async( uid ) => {
    try{
        if( uid ){
            const db = getFirestore(firebase_app)
            const docRef = doc( db, 'users', uid )
            const docSnap = await getDoc( docRef )

            if( docSnap.exists() ){
                return docSnap.data()
            } else {
                throw Error('uid is invalid')
            }
        } else {
            return null
        }
    } catch(err){
        throw err
    }
}

const getSavedDocs = async( savedDocRefs ) => {
    try {
        // console.log( savedDocRefs )
        const savedDocs = await savedDocRefs.map(async docRef => {
            const docSnap =  await getDoc(docRef.ref)
            docRef['ref'] = docSnap.data()
            return docRef
        })

        return savedDocs
    } catch (err){
        throw err
    }
}

export const AuthContextProvider = ({
    children,
}) => {
    const [user, setUser] = useState(null);
    const [updateUser, setUpdateUser] = useState(true)
    const [loading, setLoading] = useState(true);

    const handleSetLoading = (loading_state) => {
        setLoading( loading_state )
        setUpdateUser( loading_state )
    }
    console.log( user )
    console.log( auth )

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && user.uid) {
                console.log('get id token again.')
                user.getIdToken()
                    .then(idToken => {
                        console.log( idToken )
                        if( idToken ){
                            getCurrentUser(user.uid)
                                    .then(user_doc => {
                                        // console.log( user_doc )
                                        if( user_doc ){
                                            const { credit, user_type, saved } = user_doc
                                            if( credit && user_type ){
                                                // if( saved && saved.length ){
                                                //     getSavedDocs( saved )
                                                //                 .then(savedDocs => {
                                                //                     setUser({...user, credit, saved: savedDocs, user_type, idToken })                
                                                //                 })
                                                // } else {
                                                //     setUser({...user, credit, saved: [], user_type, idToken })
                                                // }

                                                const new_saved = saved && saved.length ? saved : []
                                                setUser({...user, credit, saved: new_saved, user_type, idToken })
                                                handleSetLoading(false)
                                            } else {
                                                setUser( null )
                                                handleSetLoading(false)
                                            }
                                        } else {
                                            setUser( null )
                                            handleSetLoading(false)
                                        }
                                    })
                                    .catch(err => {
                                        setUser( null )
                                        handleSetLoading(false)
                                    })
                        } else {
                            setUser( null )
                            handleSetLoading( false )
                        }
                        
                    })
                
            } else {
                setUser( null )
                handleSetLoading(false)
            }
        });

        return () => unsubscribe();
    }, [updateUser]);

    const handleClose = () => {

    }

    return (
        <AuthContext.Provider value={{ user, setUpdateUser, loading }}>
            {loading ? 
                <LoadingDialog
                    open={loading}
                    duration={5}
                    onClose={handleClose}
                    />
            : children}
        </AuthContext.Provider>
    );
};