'use client'
import React, { ReactNode } from "react";

interface PositionBoxProps {
  children: ReactNode;
  top: string;
  width?: string; 
}

const PositionBox: React.FC<PositionBoxProps> = ({ children, top , width}) => {
  return (
    <div
      style={{
        position: "absolute",
        top: top,
        padding: 0,
        width: width, 
      }}
    >
      {children}
    </div>
  );
};

export default PositionBox;
