'use client'
import React from "react";
import { Box } from "@mui/material";
import { Close } from '@mui/icons-material';
import { BLACK, LANDSCAPE_DIALOG_BACKGROUND, TextInvert } from "@/app/common/color";
import PositionBox from "@/app/components/PositionBox";

const LandscapeDialog: React.FC = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0",
        margin: "0",
        backgroundColor: BLACK
        // border: "1px black solid"
      }}
    >
      <div style={{
        position: "relative",
        width: "351px",
        height: "100%",
        gap: "0px",
        borderRadius: "16px",
        opacity: "0px",
        backgroundColor: LANDSCAPE_DIALOG_BACKGROUND,
      }}>
        <PositionBox top="13px" width="100%">
          <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "13px" }}>
            <Close style={{ color: TextInvert }} />
          </div>
        </PositionBox>
        <PositionBox top="0px" width="100%">
          <div style={{height:"100vh" , width:"100%", display:"flex" , flexDirection:"column", justifyContent:"center" }}> 
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }} >
              <Box
                component="img"
                style={{ width: "119px", height: "138.34px", padding: 0 }}
                alt="Oh My Ink Logo"
                src="./images/oh_my_ink_logo.png"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }} className="landscapeDialog_label_1">Oops!</div>
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }} >
              <div style={{ display: "flex", justifyContent: "center", width: "236px" }} className="landscapeDialog_label_2">
                It looks like landscape mode is not supported here. For the best experience, please switch to portrait mode!
              </div>
            </div>
          </div>

        </PositionBox>
      </div>
    </Box>
  );
};

export default LandscapeDialog;
