// export interface IUserContext {
//   userName: string;
//   isProUser: boolean;
//   token: number;
// }

export interface IUserContext {
  userName: string;
  isProUser: boolean;
  free_token: number;
  paid_token: number;
}

const LOCAL_STORAGE_KEY_USER_CONTEXT = "UserContext";

export const getUserContext = () => {
  const sessionStorageValue = sessionStorage.getItem(
    LOCAL_STORAGE_KEY_USER_CONTEXT
  );
  const temp = sessionStorageValue ? JSON.parse(sessionStorageValue) : {};
  // const userContext: IUserContext = {
  //   userName: temp.userName,
  //   isProUser: temp.isProUser,
  //   token: temp.token,
  // };
  const userContext: IUserContext = {
    userName: temp.userName,
    isProUser: temp.isProUser,
    free_token: temp.free_token,
    paid_token: temp.paid_token
  }
  return userContext;
};

export const setUserContext = (context: IUserContext) => {
  const contextString = JSON.stringify(context);
  sessionStorage.setItem(LOCAL_STORAGE_KEY_USER_CONTEXT, contextString);
};
