'use client'
import { useEffect, useState } from 'react';
import { detectDesktop } from '@/app/common/Util';

export const useDeviceRestriction = () => {
    const isDesktop = detectDesktop();
    const allowDesktop = true;
    const [allowedDevice, setAllowedDevice] = useState(!isDesktop);
    // console.log( allowedDevice );
    
    useEffect(() => {
        // console.log('page load allowedDevice')
        // console.log( allowDesktop );
        // console.log( isDesktop );
        if( allowDesktop || !isDesktop ){
            setAllowedDevice(true);
        }
    }, []);

    return {
        allowedDevice
    }
}