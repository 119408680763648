'use client'
import React from "react";

import { styled } from "@mui/material/styles";
import { Dialog, Box } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import { SurfaceYellow, SurfacePurple } from "@/app/common/color";
import Canvas from "@/app/components/BlankCanvas";
import PositionBox from "@/app/components/PositionBox";
import { useCountUp } from 'use-count-up'

interface LoadingDialogProps {
  open: boolean;
  duration: number;
  onClose: () => void;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: SurfacePurple,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: SurfaceYellow,
  },
}));

const LoadingDialog: React.FC<LoadingDialogProps> = ({ open, onClose, duration=0.8 }) => {
  const [isCounting, setCounting] = React.useState(false);
  const countUp = useCountUp({
    isCounting: isCounting,
    end: 98,
    duration: duration,
  });

  const resetCounting = () => {
    setCounting(false);
    countUp.value = 0;
    countUp.reset();
    setCounting(true);
  }
  React.useEffect(() => {
    if (open) {
      resetCounting();
    } else {
      countUp.value = 0;
    }
  }, [open]);

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Canvas>
        <PositionBox top="273px">
          <Box
            component="img"
            style={{ width: "119px", height: "138.34px", padding: 0 }}
            alt="Oh My Ink Logo"
            src="/images/oh_my_ink_logo.png"
          />
        </PositionBox>
        <PositionBox top="396.46px">
          <div className="loading_oh_my_ink_logo">OH MY INK!</div>
        </PositionBox>
        <PositionBox top="453px">
          <Box style={{ width: "359px", padding: 0 }}>
            <BorderLinearProgress variant="determinate" value={countUp.value as number} />
          </Box>
        </PositionBox>
        {/* <PositionBox top="496px">
          <div className="loading_label_1">Good things take time.</div>
        </PositionBox> */}
      </Canvas>
    </Dialog>
  );
};

export default LoadingDialog;
